import React from "react"

import Layout from "../../components/layout"
import {Row, Col} from 'reactstrap'
import PageHeader from '../../components/pageHeader'
import PageTemplate from '../../components/pageTemplate'
import website from '../../data/website'
import Clock from 'react-live-clock';
import gavin from '../../images/gavin.jpg'
import nat from '../../images/nat.jpg'
import {DynamicSidebar} from '../../components/dynamicSidebar'
import styled from 'styled-components'

let StyledText = styled.div`
  font: normal bold 16px / 18px "Kadwa", Helvetica, Arial, Verdana, sans-serif
`

export default () => (
  <Layout title="Contact Us">
    <PageHeader title="CONTACT US"/>
    <PageTemplate Sidebar={DynamicSidebar}>
      <div className="text-primary">
        <h2>Give the office a call</h2>
        <StyledText className="my-4 py-2 font-weight-bold text-primary">
          <div className="my-2 text-primary">Phone:</div>
          {website.phone_numbers[1]}
        </StyledText>
        <StyledText className="my-4 py-2 font-weight-bold text-primary">
          <div className="my-2 text-primary">Time:</div>
          <Clock format={'h:mm A'} ticking={true} timezone={'Australia/Sydney'} />
          <span> - </span>
          <Clock format={'DD/MM/YYYY'} timezone={'Australia/Sydney'} />
        </StyledText>
        <h3 className="my-4">Or go straight to the source</h3>
        <Row>
          <Col>
            <img src={gavin} height="300" className="mb-2"/>
            <h3 className="my-3">
              Gavin
              <div><a href="tel:+61437041154">+61 0437 041 154</a></div>
            </h3>
          </Col>
          <Col>
            <img src={nat} height="300" className="mb-2"/>
            <h3 className="my-3">
              Nat
              <div><a href="tel:+61429468754">+61 0429 468 754</a></div>
            </h3>
          </Col>
        </Row>
      </div>
    </PageTemplate>
  </Layout>
)
